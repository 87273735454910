.text-light_h6 {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.bg-price {
  background-color: #1f2127;
  /* font-family: "Public Sans", serif !important; */
}

.card_price {
  background-color: #292e3b !important;
}

.text-warning_h1 {
  color: #fcdf69;
  margin: 40px 0;
  font-size: 30px;
  /* line-height: 27px !important; */
  white-space: nowrap;
}

.span_text {
  font-size: 18px !important;
}

.span_text__bottom {
  margin-left: 260px !important;
  display: block;
  color: rgba(252, 223, 105, 0.7);
}

.bi-check::before {
  font-size: 20px;
}

.icon_done {
  color: #fcdf69;
  background-color: rgba(251, 252, 105, 0.16);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-right: 10px !important;
}

.icon_done__another{
  color: #292e3c !important;
  background-color: rgba(252, 223, 105, 1) !important;
}

.icon_done + span {
  font-size: 15px;
}

.ul_list__price {
  line-height: 40px !important;
}

.button_price {
  background-color: #1f2127 !important;
  border: none !important;
  color: white !important;
  transition: all ease-in-out 0.3s;
}

.button_price:hover {
  background-color: #fcdf69 !important;
  color: #1f2127 !important;
}

.button_price:hover + ul .icon_done{
  background-color: #fcdf69 !important;
  color: rgba(251, 252, 105, 0.16) !important;
}

.back_header {
  background-color: #292e3c;
  padding-bottom: 20px;
}

.borderTarif{
  border-color: #fcdf69 !important;
}
