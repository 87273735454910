.text-light_h6 {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.bg-price {
  background-color: #1f2127;
  /* font-family: "Public Sans", serif !important; */
}

.card_price {
  background-color: #292e3b !important;
}

.text-warning_h1 {
  color: #fcdf69;
  margin: 40px 0;
}

.modalImg:hover {
  cursor: pointer;
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modal_user__info{
  background-color: #292e3b !important;
}

.custom-close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  height: 25px;
  background-color: #292e3b;
  opacity: 1;
  z-index: 1000;
}

.custom-close::before {
  content: "x";
  color: #fff;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.custom-close:hover {
  background-color: #555;
  color: #fff;
}

.carousel-control-next {
  right: -70px !important;
}

.carousel-control-prev {
  left: -70px !important;
}

.carousel-item img {
  width: 96%;
}


.carousel-item {
  text-align: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  opacity: 1;
  background-color: #292e3b;
}

.carousel-indicators{
  position: fixed !important;
  transform: translateY(-5.5vh);
  /* background-color: rgba(0, 0,0, 0.5); */
}

.button_indicators{
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
  background-color: #292e3b !important;
}

 .button_price1{
  background-color: #fcdf69 !important;
  border: none !important;
  transition: all ease-in-out 0.3s;
  color: #292e3b !important;
}

.card_price_content {
  background-color: #292e3b !important;
}

.text_body_content p {
  color: #fff !important;
}

.back_header {
  background-color: #292E3C;
  padding-bottom: 20px;
}

.modal-body {
  max-height: 90vh;
  overflow-y: auto;
}

.modal-body::-webkit-scrollbar {
  width: 5px;
  /* display: none; */
}

.modal-body::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.opacity_content::placeholder{
  color:#888888a2 !important;
}

.som_text{
  font-size: 15px;
}

.div_more {
  transform: translateY(-60px);
  padding: 0 20px;
}

.div_more ~ img{
  border-radius: 10px;
}

.div_more p{
  background-color: rgba(0, 0,0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .carousel-control-next {
    right: -20px !important;
  }

  .carousel-control-prev {
    left: -20px !important;
  }

  .modal-body::-webkit-scrollbar{
    display: none;
  }
}