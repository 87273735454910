.text-light_h6 {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.bg-price {
  background-color: #1f2127;
  /* font-family: "Public Sans", serif !important; */
}

.text-warning_h1 {
  color: #fcdf69;
  margin: 40px 0;
}

.button_price a {
  color: #fff;
}

.button_price a:hover {
  color: #fff;
}

.text_body_content p {
  color: #fff !important;
}

.bg_1212__end {
  background-color: #292e3b !important;
}

.border_card {
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: center;
}

.border_card.border_yellow {
  border-color: #fcdf69;
}

.border_card:hover,
label:hover {
  cursor: pointer;
}

input:focus {
  box-shadow: none !important;
  border-color: inherit !important;
  outline: none !important;
}

.input_card__number {
  border-color: rgba(255, 255, 255, 0.16) !important;
}

.back_header {
  background-color: #292e3c;
  padding-bottom: 20px;
}

input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

input[type="radio"]:checked {
  border-color: #fcdf69; 
  background-color: #fff;
  border-width: 5px;
}
