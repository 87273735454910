.choosesection {
  padding-top: 200px;
  padding-bottom: 100px;
}

.choosesection_parent {
  background-color: #1f2127;
}

.back_header{
  background-color: #292E3C;
  padding-bottom: 20px;
}